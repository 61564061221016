/*
 * jQuery UI screen structure and presentation
 * This CSS file was generated by ThemeRoller, a Filament Group Project for jQuery UI
 * Author: Scott Jehl, scott@filamentgroup.com, http://www.filamentgroup.com
 * Visit ThemeRoller.com
*/

/*
 * Note: If your ThemeRoller settings have a font size set in ems, your components will scale according to their parent element's font size.
 * As a rule of thumb, set your body's font size to 62.5% to make 1em = 10px.
 * body {font-size: 62.5%;}
*/



/*UI accordion*/
.ui-accordion {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
	font-size: 1.1em;
	border-bottom: 1px solid #cccccc;
}
.ui-accordion-group {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	border: 1px solid #cccccc;
	border-bottom: none;
}
.ui-accordion-header {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	cursor: pointer;
	/*background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;*/
}
.ui-accordion-header a {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	display: block;
	font-size: 1em;
	font-weight: normal;
	text-decoration: none;
	padding: .5em .5em .5em 1.7em;
	color: #444444;
	/*background: url(images/888888_7x7_arrow_right.gif) .5em 50% no-repeat;*/
}
.ui-accordion-header a:hover {
	/*background: url(images/2694e8_7x7_arrow_right.gif) .5em 50% no-repeat;*/
	color: #0b5b98;
}
.ui-accordion-header:hover {
	/*background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;*/
	color: #0b5b98;
}
.selected .ui-accordion-header, .selected .ui-accordion-header:hover {
	/*background: #99c2ff url(images/99c2ff_40x100_textures_02_glass_50.png) 0 50% repeat-x;*/
}
.selected .ui-accordion-header a, .selected .ui-accordion-header a:hover {
	color: #000000;
	/*background: url(images/ffffff_7x7_arrow_down.gif) .5em 50% no-repeat;*/
}
.ui-accordion-content {
	/*background: #f9f9f9 url(images/f9f9f9_40x100_textures_04_highlight_hard_100.png) 0 0 repeat-x;*/
	color: #362b36;
	font-size: 1em;
}
.ui-accordion-content p {
	padding: 1em 1.7em 0.6em;
}






/*UI tabs*/
.ui-tabs-nav {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
	font-size: 1.1em;
	float: left;
	position: relative;
	z-index: 1;
	border-right: 1px solid #cccccc;
	bottom: -1px;
}
.ui-tabs-nav ul {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
}
.ui-tabs-nav li {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	float: left;
	border: 1px solid #cccccc;
	border-right: none;
}
.ui-tabs-nav li a {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	float: left;
	font-size: 1em;
	font-weight: normal;
	text-decoration: none;
	padding: .5em 1.7em;
	color: #444444;
	/*background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;*/
}
.ui-tabs-nav li a:hover {
	/*background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;*/
	color: #0b5b98;
}
.ui-tabs-nav li.ui-tabs-selected {
	border-bottom-color: #99c2ff;
}
.ui-tabs-nav li.ui-tabs-selected a, .ui-tabs-nav li.ui-tabs-selected a:hover {
	/*background: #99c2ff url(images/99c2ff_40x100_textures_02_glass_50.png) 0 50% repeat-x;*/
	color: #000000;
}
.ui-tabs-panel {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
	clear:left;
	border: 1px solid #cccccc;
	/*background: #f9f9f9 url(images/f9f9f9_40x100_textures_04_highlight_hard_100.png) 0 0 repeat-x;*/
	color: #362b36;
	padding: 1.5em 1.7em;	
	font-size: 1.1em;
}
.ui-tabs-hide {
	display: none;/* for accessible hiding: position: absolute; left: -99999999px*/;
}





/*slider*/
.ui-slider {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
	font-size: 1.1em;
	/*background: #f9f9f9 url(images/f9f9f9_40x100_textures_04_highlight_hard_100.png) 0 0 repeat-x;*/
	border: 1px solid #dddddd;
	height: .8em;
	position: relative;
}
.ui-slider-handle {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	position: absolute;
	z-index: 2;
	top: -3px;
	width: 1.2em;
	height: 1.2em;
	/*background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;*/
	border: 1px solid #cccccc;
}
.ui-slider-handle:hover {
	/*background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;*/
	border: 1px solid #b2c7d7;
}
.ui-slider-handle-active, .ui-slider-handle-active:hover {
	/*background: #99c2ff url(images/99c2ff_40x100_textures_02_glass_50.png) 0 50% repeat-x;*/
	border: 1px solid #2694e8;
}
.ui-slider-range {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	height: .8em;
	/*background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;*/
	position: absolute;
	border: 1px solid #cccccc;
	border-left: 0;
	border-right: 0;
	top: -1px;
	z-index: 1;
	opacity:.7;
	filter:Alpha(Opacity=70);
}






/*dialog*/
.ui-dialog {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
	font-size: 1.1em;
	/*background: #f9f9f9 url(images/f9f9f9_40x100_textures_04_highlight_hard_100.png) 0 0 repeat-x;*/
	color: #362b36;
	border: 4px solid #dddddd;
	position: relative;
}
.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	z-index: 99999;
}
.ui-resizable .ui-resizable-handle {
	display: block; 
}
body .ui-resizable-disabled .ui-resizable-handle { display: none; } /* use 'body' to make it more specific (css order) */
body .ui-resizable-autohide .ui-resizable-handle { display: none; } /* use 'body' to make it more specific (css order) */
.ui-resizable-n { 
	cursor: n-resize; 
	height: 7px; 
	width: 100%; 
	top: -5px; 
	left: 0px;  
}
.ui-resizable-s { 
	cursor: s-resize; 
	height: 7px; 
	width: 100%; 
	bottom: -5px; 
	left: 0px; 
}
.ui-resizable-e { 
	cursor: e-resize; 
	width: 7px; 
	right: -5px; 
	top: 0px; 
	height: 100%; 
}
.ui-resizable-w { 
	cursor: w-resize; 
	width: 7px; 
	left: -5px; 
	top: 0px; 
	height: 100%;
}
.ui-resizable-se { 
	cursor: se-resize; 
	width: 13px; 
	height: 13px; 
	right: 0px; 
	bottom: 0px; 
	/*background: url(images/222222_11x11_icon_resize_se.gif) no-repeat 0 0;*/
}
.ui-resizable-sw { 
	cursor: sw-resize; 
	width: 9px; 
	height: 9px; 
	left: 0px; 
	bottom: 0px;  
}
.ui-resizable-nw { 
	cursor: nw-resize; 
	width: 9px; 
	height: 9px; 
	left: 0px; 
	top: 0px; 
}
.ui-resizable-ne { 
	cursor: ne-resize; 
	width: 9px; 
	height: 9px; 
	right: 0px; 
	top: 0px; 
}
.ui-dialog-titlebar {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	padding: .5em 1.5em .5em 1em;
	color: #444444;
	/*background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;*/
	border-bottom: 1px solid #cccccc;
	font-size: 1em;
	font-weight: normal;
	position: relative;
}
.ui-dialog-title {}
.ui-dialog-titlebar-close {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	/*background: url(images/888888_11x11_icon_close.gif) 0 0 no-repeat;*/
	position: absolute;
	right: 8px;
	top: .7em;
	width: 11px;
	height: 11px;
	z-index: 100;
}
.ui-dialog-titlebar-close-hover, .ui-dialog-titlebar-close:hover {
	/*background: url(images/2694e8_11x11_icon_close.gif) 0 0 no-repeat;*/
}
.ui-dialog-titlebar-close:active {
	/*background: url(images/ffffff_11x11_icon_close.gif) 0 0 no-repeat;*/
}
.ui-dialog-titlebar-close span {
	display: none;
}
.ui-dialog-content {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	color: #362b36;
	padding: 1.5em 1.7em;	
}
.ui-dialog-buttonpane {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: left;
	border-top: 1px solid #dddddd;
	background: #f9f9f9;
}
.ui-dialog-buttonpane button {
	margin: .5em 0 .5em 8px;
	color: #444444;
	/*background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;*/
	font-size: 1em;
	border: 1px solid #cccccc;
	cursor: pointer;
	padding: .2em .6em .3em .6em;
	line-height: 1.4em;
}
.ui-dialog-buttonpane button:hover {
	color: #0b5b98;
	/*background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;*/
	border: 1px solid #b2c7d7;
}
.ui-dialog-buttonpane button:active {
	color: #000000;
	/*background: #99c2ff url(images/99c2ff_40x100_textures_02_glass_50.png) 0 50% repeat-x;*/
	border: 1px solid #2694e8;
}
/* This file skins dialog */
.ui-dialog.ui-draggable .ui-dialog-titlebar,
.ui-dialog.ui-draggable .ui-dialog-titlebar {
	cursor: move;
}







/*datepicker*/
/* Main Style Sheet for jQuery UI date picker */
.ui-datepicker-div, .ui-datepicker-inline, #ui-datepicker-div {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
	/*background: #f9f9f9 url(images/f9f9f9_40x100_textures_04_highlight_hard_100.png) 0 0 repeat-x;*/
	font-size: 1.1em;
	border: 4px solid #dddddd;
	width: 15.5em;
	padding: 2.5em .5em .5em .5em;
	position: relative;
}
.ui-datepicker-div, #ui-datepicker-div {
	z-index: 9999; /*must have*/
	display: none;
}
.ui-datepicker-inline {
	float: left;
	display: block;
}
.ui-datepicker-control {
	display: none;
}
.ui-datepicker-current {
	display: none;
}
.ui-datepicker-next, .ui-datepicker-prev {
	position: absolute;
	left: .5em;
	top: .5em;
	/*background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;*/
}
.ui-datepicker-next {
	left: 14.6em;
}
.ui-datepicker-next:hover, .ui-datepicker-prev:hover {
	/*background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;*/
}
.ui-datepicker-next a, .ui-datepicker-prev a {
	text-indent: -999999px;
	width: 1.3em;
	height: 1.4em;
	display: block;
	font-size: 1em;
	background: url(images/888888_7x7_arrow_left.gif) 50% 50% no-repeat;
	border: 1px solid #cccccc;
	cursor: pointer;
}
.ui-datepicker-next a {
	background: url(images/888888_7x7_arrow_right.gif) 50% 50% no-repeat;
}
.ui-datepicker-prev a:hover {
	background: url(images/2694e8_7x7_arrow_left.gif) 50% 50% no-repeat;
}
.ui-datepicker-next a:hover {
	background: url(images/2694e8_7x7_arrow_right.gif) 50% 50% no-repeat;
}
.ui-datepicker-prev a:active {
	background: url(images/ffffff_7x7_arrow_left.gif) 50% 50% no-repeat;
}
.ui-datepicker-next a:active {
	background: url(images/ffffff_7x7_arrow_right.gif) 50% 50% no-repeat;
}
.ui-datepicker-header select {
	border: 1px solid #cccccc;
	color: #444444;
	background: #e6e6e6;
	font-size: 1em;
	line-height: 1.4em;
	position: absolute;
	top: .5em;
	margin: 0 !important;
}
.ui-datepicker-header option:focus, .ui-datepicker-header option:hover {
	background: #f0f0f0;
}
.ui-datepicker-header select.ui-datepicker-new-month {
	width: 7em;
	left: 2.2em;
}
.ui-datepicker-header select.ui-datepicker-new-year {
	width: 5em;
	left: 9.4em;
}
table.ui-datepicker {
	width: 15.5em;
	text-align: right;
}
table.ui-datepicker td a {
	padding: .1em .3em .1em 0;
	display: block;
	color: #444444;
	background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;
	cursor: pointer;
	border: 1px solid #f9f9f9;
}
table.ui-datepicker td a:hover {
	border: 1px solid #b2c7d7;
	color: #0b5b98;
	background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;
}
table.ui-datepicker td a:active {
	border: 1px solid #2694e8;
	color: #000000;
	background: #99c2ff url(images/99c2ff_40x100_textures_02_glass_50.png) 0 50% repeat-x;
}
table.ui-datepicker .ui-datepicker-title-row td {
	padding: .3em 0;
	text-align: center;
	font-size: .9em;
	color: #362b36;
	text-transform: uppercase;
}
table.ui-datepicker .ui-datepicker-title-row td a {
	color: #362b36;
}
.ui-datepicker-cover {
	display: none;
	display/**/: block;
	position: absolute;
	z-index: -1;
	filter: mask();
	top: -4px;
	left: -4px;
	width: 193px;
	height: 200px;
}














/*
Generic ThemeRoller Classes
>> Make your jQuery Components ThemeRoller-Compatible!
*/

/*component global class*/
.ui-component {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
	font-family: Lucida Grande, Lucida Sans, Arial, sans-serif;
	font-size: 1.1em;
}
/*component content styles*/
.ui-component-content {
	border: 1px solid #dddddd;
	background: #f9f9f9 url(images/f9f9f9_40x100_textures_04_highlight_hard_100.png) 0 0 repeat-x;
	color: #362b36;
}
.ui-component-content a {
	color: #362b36;
	text-decoration: underline;
}
/*component states*/
.ui-default-state {
	border: 1px solid #cccccc;
	background: #e6e6e6 url(images/e6e6e6_40x100_textures_02_glass_80.png) 0 50% repeat-x;
	font-weight: normal;
	color: #444444 !important;
}
.ui-default-state a {
	color: #444444;
}
.ui-default-state:hover, .ui-hover-state {
	border: 1px solid #b2c7d7;
	background: #f0f0f0 url(images/f0f0f0_40x100_textures_02_glass_100.png) 0 50% repeat-x;
	font-weight: normal;
	color: #0b5b98 !important;
}
.ui-hover-state a {
	color: #0b5b98;
}
.ui-default-state:active, .ui-active-state {
	border: 1px solid #2694e8;
	background: #99c2ff url(images/99c2ff_40x100_textures_02_glass_50.png) 0 50% repeat-x;
	font-weight: normal;
	color: #000000 !important;
	outline: none;
}
.ui-active-state a {
	color: #000000;
	outline: none;
}
/*icons*/
.ui-arrow-right-default {background: url(images/888888_7x7_arrow_right.gif) no-repeat 50% 50%;}
.ui-arrow-right-default:hover, .ui-arrow-right-hover {background: url(images/2694e8_7x7_arrow_right.gif) no-repeat 50% 50%;}
.ui-arrow-right-default:active, .ui-arrow-right-active {background: url(images/ffffff_7x7_arrow_right.gif) no-repeat 50% 50%;}
.ui-arrow-right-content {background: url(images/222222_7x7_arrow_right.gif) no-repeat 50% 50%;}

.ui-arrow-left-default {background: url(images/888888_7x7_arrow_left.gif) no-repeat 50% 50%;}
.ui-arrow-left-default:hover, .ui-arrow-left-hover {background: url(images/2694e8_7x7_arrow_left.gif) no-repeat 50% 50%;}
.ui-arrow-left-default:active, .ui-arrow-left-active {background: url(images/ffffff_7x7_arrow_left.gif) no-repeat 50% 50%;}
.ui-arrow-left-content {background: url(images/222222_7x7_arrow_left.gif) no-repeat 50% 50%;}

.ui-arrow-down-default {background: url(images/888888_7x7_arrow_down.gif) no-repeat 50% 50%;}
.ui-arrow-down-default:hover, .ui-arrow-down-hover {background: url(images/2694e8_7x7_arrow_down.gif) no-repeat 50% 50%;}
.ui-arrow-down-default:active, .ui-arrow-down-active {background: url(images/ffffff_7x7_arrow_down.gif) no-repeat 50% 50%;}
.ui-arrow-down-content {background: url(images/222222_7x7_arrow_down.gif) no-repeat 50% 50%;}

.ui-arrow-up-default {background: url(images/888888_7x7_arrow_up.gif) no-repeat 50% 50%;}
.ui-arrow-up-default:hover, .ui-arrow-up-hover {background: url(images/2694e8_7x7_arrow_up.gif) no-repeat 50% 50%;}
.ui-arrow-up-default:active, .ui-arrow-up-active {background: url(images/ffffff_7x7_arrow_up.gif) no-repeat 50% 50%;}
.ui-arrow-up-content {background: url(images/222222_7x7_arrow_up.gif) no-repeat 50% 50%;}

.ui-close-default {background: url(images/888888_11x11_icon_close.gif) no-repeat 50% 50%;}
.ui-close-default:hover, .ui-close-hover {background: url(images/2694e8_11x11_icon_close.gif) no-repeat 50% 50%;}
.ui-close-default:active, .ui-close-active {background: url(images/ffffff_11x11_icon_close.gif) no-repeat 50% 50%;}
.ui-close-content {background: url(images/ffffff_11x11_icon_close.gif) no-repeat 50% 50%;}

.ui-folder-closed-default {background: url(images/888888_11x11_icon_folder_closed.gif) no-repeat 50% 50%;}
.ui-folder-closed-default:hover, .ui-folder-closed-hover {background: url(images/2694e8_11x11_icon_folder_closed.gif) no-repeat 50% 50%;}
.ui-folder-closed-default:active, .ui-folder-closed-active {background: url(images/ffffff_11x11_icon_folder_closed.gif) no-repeat 50% 50%;}
.ui-folder-closed-content {background: url(images/888888_11x11_icon_folder_closed.gif) no-repeat 50% 50%;}

.ui-folder-open-default {background: url(images/888888_11x11_icon_folder_open.gif) no-repeat 50% 50%;}
.ui-folder-open-default:hover, .ui-folder-open-hover {background: url(images/2694e8_11x11_icon_folder_open.gif) no-repeat 50% 50%;}
.ui-folder-open-default:active, .ui-folder-open-active {background: url(images/ffffff_11x11_icon_folder_open.gif) no-repeat 50% 50%;}
.ui-folder-open-content {background: url(images/ffffff_11x11_icon_folder_open.gif) no-repeat 50% 50%;}

.ui-doc-default {background: url(images/888888_11x11_icon_doc.gif) no-repeat 50% 50%;}
.ui-doc-default:hover, .ui-doc-hover {background: url(images/2694e8_11x11_icon_doc.gif) no-repeat 50% 50%;}
.ui-doc-default:active, .ui-doc-active {background: url(images/ffffff_11x11_icon_doc.gif) no-repeat 50% 50%;}
.ui-doc-content {background: url(images/222222_11x11_icon_doc.gif) no-repeat 50% 50%;}

.ui-arrows-leftright-default {background: url(images/888888_11x11_icon_arrows_leftright.gif) no-repeat 50% 50%;}
.ui-arrows-leftright-default:hover, .ui-arrows-leftright-hover {background: url(images/2694e8_11x11_icon_arrows_leftright.gif) no-repeat 50% 50%;}
.ui-arrows-leftright-default:active, .ui-arrows-leftright-active {background: url(images/ffffff_11x11_icon_arrows_leftright.gif) no-repeat 50% 50%;}
.ui-arrows-leftright-content {background: url(images/222222_11x11_icon_arrows_leftright.gif) no-repeat 50% 50%;}

.ui-arrows-updown-default {background: url(images/888888_11x11_icon_arrows_updown.gif) no-repeat 50% 50%;}
.ui-arrows-updown-default:hover, .ui-arrows-updown-hover {background: url(images/2694e8_11x11_icon_arrows_updown.gif) no-repeat 50% 50%;}
.ui-arrows-updown-default:active, .ui-arrows-updown-active {background: url(images/ffffff_11x11_icon_arrows_updown.gif) no-repeat 50% 50%;}
.ui-arrows-updown-content {background: url(images/222222_11x11_icon_arrows_updown.gif) no-repeat 50% 50%;}

.ui-minus-default {background: url(images/888888_11x11_icon_minus.gif) no-repeat 50% 50%;}
.ui-minus-default:hover, .ui-minus-hover {background: url(images/2694e8_11x11_icon_minus.gif) no-repeat 50% 50%;}
.ui-minus-default:active, .ui-minus-active {background: url(images/ffffff_11x11_icon_minus.gif) no-repeat 50% 50%;}
.ui-minus-content {background: url(images/222222_11x11_icon_minus.gif) no-repeat 50% 50%;}

.ui-plus-default {background: url(images/888888_11x11_icon_plus.gif) no-repeat 50% 50%;}
.ui-plus-default:hover, .ui-plus-hover {background: url(images/2694e8_11x11_icon_plus.gif) no-repeat 50% 50%;}
.ui-plus-default:active, .ui-plus-active {background: url(images/ffffff_11x11_icon_plus.gif) no-repeat 50% 50%;}
.ui-plus-content {background: url(images/222222_11x11_icon_plus.gif) no-repeat 50% 50%;}

/*hidden elements*/
.ui-hidden {
	display: none;/* for accessible hiding: position: absolute; left: -99999999px*/;
}
.ui-accessible-hidden {
	 position: absolute; left: -99999999px;
}
/*reset styles*/
.ui-reset {
	/*resets*/margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none;
}
/*clearfix class*/
.ui-clearfix:after {
    content: "."; 
    display: block; 
    height: 0; 
    clear: both; 
    visibility: hidden;
}
.ui-clearfix {display: inline-block;}
/* Hides from IE-mac \*/
* html .ui-clearfix {height: 1%;}
.ui-clearfix {display: block;}
/* End hide from IE-mac */

/* Note: for resizable styles, use the styles listed above in the dialog section */


