/* Generic context menu styles */
.contextMenu {
	font-size:11px;
	position: absolute;
	min-width: 100px;
	z-index: 99999;
	border: solid 1px #999;
	background: #EEE;
	padding: 0px;
	margin: 0px;
	display: none;
}

.contextMenu LI {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.contextMenu A {
	color: #333;
	text-decoration: none;
	display: block;
	line-height: 20px;
	height: 20px;
	background-position: 6px center;
	background-repeat: no-repeat;
	outline: none;
	padding: 1px 5px;
	padding-left: 28px;
	border-bottom:1px solid #ddd;
}

.contextMenu LI.hover A {
	color: #FFF;
	background-color: #3399FF;
}

.contextMenu LI.disabled A {
	color: #AAA;
	cursor: default;
}

.contextMenu LI.hover.disabled A {
	background-color: transparent;
}

.contextMenu LI.separator {
	border-top: solid 1px #CCC;
}

/*
	Adding Icons
	
	You can add icons to the context menu by adding
	classes to the respective LI element(s)
*/

.contextMenu LI.edit A { background-image: url(/img/edit.png); }
.contextMenu LI.cut A { background-image: url(/img/cut.png); }
.contextMenu LI.copy A { background-image: url(/img/copy.png); }
.contextMenu LI.paste A { background-image: url(/img/page_white_paste.png); }
.contextMenu LI.delete A { background-image: url(/img/delete.png); }
.contextMenu LI.quit A { background-image: url(/img/door.png); }
