	/*	-------------------------------------------------------------
	Belbus CSS
	vPlanner 3
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	Beschijving:	Base Style
	Bestandsnaam:	default.css
	Versie:			3.0.0
	Datum:			jan 5, 2008
	-------------------------------------------------------------	*/
	/*	-------------------------------------------------------------
	Basis Elementen
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/
	/*
developer
*/

	#todo_collapse {
	    cursor: pointer;
	}

	.todo {
	    position: absolute;
	    background: #ddd;
	    padding: 10px;
	    border: solid 2px black;
	}

	.todo ul {
	    margin: 0 0 0 10px;
	}

	.todo ul li {
	    font-size: 9pt;
	    font-weight: bold;
	}

	.todo ul li li {
	    margin: 0 0 0 10px;
	    font-weight: normal;
	    list-style-type: square;
	}
	/*
--
*/

	html,
	body {
	    height: 100%;
	}

	a:active {
	    outline: none;
	}

	a:focus {
	    -moz-outline-style: none;
	}

	input:active {
	    outline: none;
	}

	input:focus {
	    -moz-outline-style: none;
	}

	img {
	    border: 0;
	}

	body {
	    font-family: Calibri, Arial;
	    margin: 0;
	    padding: 0;
	    background-color: #fff;
	    height: 100%;
	}

	#outer-wrapper {
	    width: 100%;
	    min-height: 100%;
	    position: relative;
	    /*
	-moz-user-select:none;
    -khtml-user-select:none;
    */
	}

	#header {
	    padding: 20px 0 0 0;
	    height: 70px;
	    background: url(/img/bg_header_loop.png) repeat-x;
	    border-bottom: 1px solid #9b9b9b;
	}

	#sitenaam {
	    position: absolute;
	    right: 30px;
	    font-size: 26px;
	    text-shadow: #fff 1px 1px 0;
	}

	#datum {
	    position: absolute;
	    left: 30px;
	    font-size: 26px;
	    text-shadow: #fff 1px 1px 0;
	    text-transform: capitalize;
	}

	#nav {
	    margin: 40px 0 0 0;
	    height: 38px;
	    text-align: center;
	}

	#nav li {
	    display: inline;
	    list-style-type: none;
	}

	#nav li a:link,
	#nav li a:visited {
	    font-size: 14px;
	    text-decoration: none;
	    display: inline;
	    padding: 4px 12px 3px 12px;
	    color: #333;
	    font-weight: bold;
	    outline: none;
	}

	#nav li a:hover {
	    background-color: #dfdfdf;
	    color: #333;
	    text-decoration: none;
	    border-radius: 5px;
	}

	#nav #currentpage {
	    background-color: #666;
	    color: #fff;
	    -moz-border-radius: 5px;
	    -webkit-border-radius: 5px;
	}

	#subnav {
	    background-color: #f4f4f4;
	    text-align: center;
	    padding: 2px 0 5px 0;
	    margin: -7px auto;
	    border-bottom: 1px solid #ebebeb;
	}

	#subnav ul {
	    margin: 10px 0 -2px 0;
	}

	#subnav li {
	    display: inline;
	    list-style-type: none;
	}

	#subnav li a:link,
	#subnav li a:visited {
	    background-color: #d8dfea;
	    font-size: 13px;
	    text-decoration: none;
	    display: inline;
	    padding: 4px 12px 3px 12px;
	    color: #333;
	    margin: 0 5px 0 5px;
	    font-weight: bold;
	    outline: none;
	    -webkit-border-top-left-radius: 5px;
	    -webkit-border-top-right-radius: 5px;
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	}

	#subnav li li {
	    margin: 2px 5px 0 0;
	    padding: 7px 0 0 0;
	}

	#subnav li li a:link,
	#subnav li li a:visited {
	    background-color: #f4f4f4;
	    font-size: 11px;
	    text-decoration: none;
	    padding: 4px;
	    margin: 7px;
	    font-weight: bold;
	    outline: none;
	    -webkit-border-top-left-radius: 5px;
	    -webkit-border-top-right-radius: 5px;
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	}

	#subnav li a:hover {
	    padding: 4px 12px 4px 12px;
	    background-color: #fff;
	    color: #333;
	    border: 1px solid #d8dfea;
	    border-bottom: 0;
		
	}

	#subnav li li a:hover {
	    padding: 4px;
	    background-color: #f4f4f4;
	    border: 0;
	    color: #999;
	}

	#subnav #currentpage {
	    padding: 4px 12px 4px 12px;
	    background-color: #fff;
	    color: #333;
	    border: 1px solid #d8dfea;
	    border-bottom: 0;
	}

	.prullenbak_keuze a:link,
	.prullenbak_keuze a:visited {
	    background-color: #333;
	}

	.msg_body1 {
	    display: none;
	    border: 1px solid #d8dfea;
	    border-top: none;
	    margin: 2px 5px 5px 5px;
	    background-color: #f4f4f4;
	    text-align: left;
	    padding: 0 5px 10px 5px;
	    position: absolute;
	}

	#subnav .prullenbak {
	    float: right;
	    margin: -17px 215px 0 -100px;
	}

	#factnav {
	    text-align: center;
	    padding: 2px 0 5px 0px;
	    margin: 20px auto 0;
	}

	#factnav ul {
	    margin: 10px 0 -2px 0;
	}

	#factnav li {
	    display: inline;
	    list-style-type: none;
	}

	#factnav li a:link,
	#factnav li a:visited {
	    background-color: #e5e5e5;
	    font-size: 11px;
	    text-decoration: none;
	    display: inline;
	    padding: 4px 12px 3px 12px;
	    color: #333;
	    margin: 0 5px 0 5px;
	    font-weight: bold;
	    outline: none;
	    -webkit-border-top-left-radius: 5px;
	    -webkit-border-top-right-radius: 5px;
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	}

	#factnav li li {
	    margin: 2px 5px 0 0;
	    padding: 7px 0 0 0;
	}

	#factnav li li a:link,
	#factnav li li a:visited {
	    background-color: #f4f4f4;
	    font-size: 11px;
	    text-decoration: none;
	    padding: 4px;
	    margin: 7px;
	    font-weight: bold;
	    outline: none;
	    -webkit-border-top-left-radius: 5px;
	    -webkit-border-top-right-radius: 5px;
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	}

	#factnav li a:hover {
	    padding: 4px 12px 4px 12px;
	    background-color: #fff;
	    color: #333;
	    border: 1px solid #d8dfea;
	    border-bottom: 0;
	}

	#factnav li li a:hover {
	    padding: 4px;
	    background-color: #f4f4f4;
	    border: 0;
	    color: #999;
	}

	#factnav #currentpage {
	    padding: 4px 12px 4px 12px;
	    background-color: #fff;
	    color: #333;
	    border: 1px solid #d8dfea;
	    border-bottom: 0;
	}

	.option {
	    border: 1px solid #999;
	    font-weight: bold;
	    background: url(/img/buttonshadow.png) repeat-x;
	    font-size: 10px;
	    -moz-border-radius: 3px;
	}

	.admin {
	    color: #990000;
	}

	#login {
	    position: absolute;
	    right: 30px;
	    margin: -12px 0 0 0;
	    font-size: 10px;
	    border: 1px solid #9b9b9b;
	    border-bottom: 0;
	    background-color: #f4f4f4;
	    padding: 5px;
	}

	#login a:link,
	#login a:visited {
	    color: #0187ce;
	    text-decoration: none;
	}

	#login a:hover {
	    text-decoration: underline;
	}

	#content-all {
	    padding: 10px;
	}

	#contentelement {
	    margin: 20px auto;
	    width: 1300px;
	    background-color: #fff;
	}

	#ajaxklantentabel {
	    width: 1300px;
	    border: 1px solid #ddd;
	    -moz-border-radius-bottomleft: 5px;
	    -moz-border-radius-bottomright: 5px;
	}

	#klantentabel {
	    width: 1300px;
	    border: 1px solid #ddd;
	    -webkit-border-radius: 5px;
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	}

	#ajaxklanten td {
	    text-align: left;
	}

	#medewerkerstabel {
	    width: 1300px;
	    border: 1px solid #ddd;
	    -webkit-border-radius: 5px;
	    -moz-border-radius: 5px;
	}

	#naam input {
	    position: absolute;
	    float: right;
	}

	#contentelement tr:hover {
	    background-color: #ddd;
	}

	.highlight {
	    display: block;
	}

	#contentelement td {
	    border-bottom: 1px solid #ddd;
	    padding: 2px 12px 2px 12px;
	    color: #333;
	    font-size: 12px;
	}

	.top {
	    background-color: #ddd;
	    -webkit-border-radius: 5px;
	}

	td#calender table form {
	    border: 2px solid #333;
	    font-size: 9px;
	}

	#contentelement th {
	    font: bold 11px "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
	    color: #333;
	    border-right: 1px solid #ddd;
	    border-bottom: 1px solid #ddd;
	    border-top: 1px solid #ddd;
	    letter-spacing: 1px;
	    text-align: left;
	    padding: 6px 6px 6px 12px;
	    background: #fff url(/img/bg_header.jpg) no-repeat;
	}

	#contentelement th:hover {
	    font: bold 11px "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
	    color: #333;
	    cursor: default;
	    border-right: 1px solid #ddd;
	    border-bottom: 1px solid #ddd;
	    border-top: 1px solid #ddd;
	    letter-spacing: 1px;
	    text-align: left;
	    padding: 6px 6px 6px 12px;
	    background: #fff url(/img/bg_header_hover.jpg) no-repeat;
	}

	#facturering_zeevang {
	    -moz-border-radius: 5px;
	    background-color: #ccc;
	}

	#facturering_zeevang th {
	    font: bold 10px "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
	    color: #333;
	    cursor: default;
	    border-right: 1px solid #ddd;
	    border-bottom: 1px solid #ddd;
	    border-top: 1px solid #ddd;
	    letter-spacing: 1px;
	    text-align: center;
	    background: #fff url(/img/bg_header_hover.jpg) no-repeat;
	}

	#footer {
	    padding: 5px 0 0 0;
	    bottom: 0;
	    width: 100%;
	    height: 15px;
	    /* Height of the footer */
	    background: #fff;
	    font-size: 10px;
	    border-top: 1px solid #9b9b9b;
	    text-align: center;
	}

	td#calender {
	    margin: 10px 0 0 0;
	    font-size: 11px;
	    font-family: verdana, arial, sans-serif;
	    color: #545454;
	    min-width: 24em;
	    width: 200px;
	    z-index: 9999;
	    text-align: center;
	    /* Change the font-size to suit your design's CSS. The following line is for the demo that has a 12px font-size defined on the body tag */
	    /* For Example: If using the YUI font CSS, uncomment the following line to get a 10px font-size within the datePicker */
	    /* font:900 77%/77% Verdana; */
	    background: transparent;
	    /* Mozilla & Webkit extensions to stop text-selection. Remove if you wish to validate the CSS */
	    -moz-user-select: none;
	    -khtml-user-select: none;
	}
	/* The table */

	td#calender table.datepicker {
	    position: relative;
	    margin: 0;
	    padding: 0;
	    border: 1px solid #ccc;
	    background: #fff url(/img/gradient-e5e5e5-ffffff.gif) repeat-x 0 -20px;
	    text-align: center;
	    width: 100%;
	    border-spacing: 2px;
	    table-layout: fixed;
	    border-collapse: separate;
	}
	/* Common TD & TH styling */

	td#calender table.datepicker td {
	    border: 1px solid #ccc;
	    padding: 0;
	    text-align: center;
	    vertical-align: middle;
	    /* Opera requires a line-height bigger than 1em in order to redraw properly */
	    line-height: 1.2em;
	    cursor: pointer;
	    background: #fff url(/img/gradient-e5e5e5-ffffff.gif) repeat-x 0 -40px;
	    width: 3em;
	    height: 3em !important;
	    height: 2.8em;
	    outline: none;
	}

	td#calender table.datepicker th {
	    border: 0 none;
	    padding: 0;
	    line-height: 1em;
	    font-weight: bold;
	    color: #222;
	    text-align: center;
	    vertical-align: middle;
	}
	/* The "mon tue wed etc" day header button styles */

	td#calender table th.date-picker-day-header {
	    text-transform: lowercase;
	    width: 3em;
	}
	/* The "todays date" style */

	td#calender table span.date-picker-today {
	    background: #fff url(/img/bullet2.gif) no-repeat 0 0;
	    color: rgb(100, 100, 100) !important;
	    padding: 7px;
	}
	/* The "active cursor" style */

	td#calender table tbody td:hover {
	    background: #fff url(/img/bg_header_hover.jpg) no-repeat 0 0;
	    cursor: pointer;
	    border-color: rgb(100, 130, 170);
	}

	td#calender a:link,
	td#calender a:visited {
	    font-weight: bold;
	    color: #333;
	    text-decoration: none;
	}

	td#calender a:hover {
	    color: rgb(100, 130, 170);
	    text-decoration: none;
	}

	td#calender a:active {
	    color: #000;
	    text-decoration: underline;
	}

	#bus table {
	    border: 0;
	    font-size: 14px;
	}

	#bus table table th {
	    border: 0;
	    border-bottom: 2px solid #333;
	    border-right: 1px solid #333;
	    background-color: #666;
	    color: #fff;
	}

	#bus table td {
	    border: 1px solid #ddd;
	}

	#bus table tr {
	    border: 0;
	}

	#bus table a:link,
	#bus table a:visited {
	    color: #333;
	    text-decoration: none;
	}

	#bus table a:hover {
	    border-bottom: 1px solid #333;
	}

	#loginform {
	    margin: 120px auto -50px;
	    width: 300px;
	}

	#logintable {
	    width: 250px;
	    font-size: 11px;
	    padding: 20px;
	    background-color: #f3f3f3;
	    border: 0;
	    margin: 20px auto 50px auto;
	    border-right: 2px solid #ddd;
	    border-bottom: 2px solid #ddd;
	    -moz-border-radius: 5px;
	    -webkit-border-radius: 10px;
	    overflow: auto;
	}

	#logintable form {
	    margin: 0 0 0 30px;
	}

	#logintable label,
	#logintable input {
	    display: block;
	    width: 100px;
	    float: left;
	    margin: 0 0 10px 0;
	}

	#logintable label {
	    width: 75px;
	    padding: 0 30px 0 0;
	    font-weight: bold;
	    font-size: 11px;
	}

	#logintable h2 {
	    border-bottom: 1px dashed #ddd;
	}

	#logintable input {
	    border: 1px solid #ddd;
	}

	#meldingNOK {
	    position: absolute;
	    left: 40%;
	    top: 130px;
	    padding: 5px 0 0 30px;
	    text-align: left;
	    font-size: 10px;
	    font-weight: bold;
	    border: 1px solid #e4c26a;
	    width: 450px;
	    height: 20px;
	    color: #6c5964;
	    background: #ffffcc url(/img/cross_circle.png) no-repeat 5px;
	}

	#meldingOK {
	    position: absolute;
	    left: 40%;
	    top: 130px;
	    padding: 7px 5px 3px 25px;
	    width: 400px;
	    text-align: left;
	    font-size: 10px;
	    font-weight: bold;
	    border: 1px solid #e4c26a;
	    height: 20px;
	    color: #595959;
	    background: #ffffcc url(/img/tick_circle.png) no-repeat 5px;
	    border-collapse: collapse;
	    text-indent: 2px;
	    text-align: left;
	}

	.active {
	    background-color: #333;
	}

	#klanten_form form {
	    font-size: 11px;
	    font-weight: bold;
	    color: #666;
	    text-align: left;
	}

	#klanten_form .lijn {
	    border-top: 1px solid #C0C0C0;
	    width: 100%;
	    margin: 0 0 30px 0;
	}

	#klanten_form input {
	    display: block;
	    margin-left: 180px;
	    margin-top: -20px;
	}

	#klanten_form label {
	    padding-left: 60px;
	}

	#klanten_form select {
	    display: block;
	    margin-left: 170px;
	    margin-top: -20px;
	}

	#klanten_form h3 {
	    padding-left: 20px;
	    font-size: 19px;
	    color: #333;
	    font-weight: normal;
	}

	#klanten_form .huisnr {
	    width: 20px;
	}

	#klanten_form .toevoeging {
	    width: 20px;
	    margin-left: 230px;
	}
	/*---------------------*/
	/*Afspraken Form       */
	/*---------------------*/

	#afspraak_form form {
	    font-size: 11px;
	    font-weight: bold;
	    color: #666;
	    text-align: left;
	}

	#afspraak_form .lijn {
	    border-top: 1px solid #C0C0C0;
	    width: 100%;
	    margin: 0 0 10px 0;
	}

	#afspraak_form input {
	    display: block;
	    margin-left: 90px;
	    margin-top: -20px;
	}

	#afspraak_form label {
	    padding-left: 20px;
	}

	#afspraak_form select {
	    display: block;
	    margin-left: 170px;
	    margin-top: -20px;
	}

	#afspraak_form h3 {
	    padding-left: 20px;
	    font-size: 19px;
	    color: #333;
	    font-weight: normal;
	}

	#afspraak_form .huisnr {
	    width: 20px;
	}

	#afspraak_form .toevoeging {
	    width: 20px;
	    margin-left: 230px;
	}

	#klanten_form_wijzig_wrap {
	    margin: 0 0 0 0;
	    padding: 0 0 0 20px;
	}

	#klanten_form_wijzig_links,
	#klanten_form_wijzig_center,
	#klanten_form_wijzig_rechts {
	    float: left;
	    margin-right: 10px;
	    padding: 1px 0 0 0;
	    width: 260px;
	    height: 250px;
	    padding: 10px;
	    background-color: #ddd;
	    -moz-border-radius: 5px;
	}

	#klanten_form_wijzig_links,
	#klanten_form_wijzig_center {
	    border-right: dotted 1px #ddd;
	}

	#klanten_form_wijzig input[type="submit"] {
	    margin-left: 167px;
	}

	#klanten_form_wijzig select {
	    display: block;
	    margin-left: 159px;
	    margin-top: -20px;
	}

	.button {
	    border: 1px solid #666;
	    color: #000;
	    background: #fff url(/img/buttonshadow.png) repeat-x bottom;
	    background-color: #fff;
	    vertical-align: middle;
	    text-decoration: none;
	    cursor: pointer;
	    margin: 1px;
	    padding: 0.4em 0.9em;
	    font-size: 10px;
	    display: inline;
	    font-weight: bold;
	    -moz-border-radius: 3px;
	}

	.button1 {
	    font-family: tahoma;
	    border: 1px solid #333;
	    color: #333;
	    background: #fff url(/img/buttonshadow.png) repeat-x bottom;
	    background-color: #fff;
	    vertical-align: middle;
	    text-decoration: none;
	    cursor: pointer;
	    padding: 0.3em 0.5em;
	    font-size: 10px;
	    font-weight: bold;
	    -moz-border-radius: 3px;
	}

	.button1 img {
	    margin: 0 0 -3px 0;
	}

	#tooltip {
	    position: absolute;
	    z-index: 3000;
	    border: 1px solid #c0c0c0;
	    background-color: #ffffcc;
	    padding: 5px;
	    opacity: 0.85;
	    font-size: 9px;
	}

	#tooltip h3,
	#tooltip div {
	    margin: 0;
	}

	.opties {
	    cursor: pointer;
	}

	.sub {
	    background: #FBFBFB;
	    font-size: 10px;
	    border-bottom: 1px solid #ddd;
	}

	.submenu ul {
	    margin: 8px 0 5px 550px;
	    padding: 3px 0 0 0;
	}

	.submenu li {
	    display: inline;
	    list-style-type: none;
	}

	.submenu img {
	    padding: 0 2px 0 4px;
	}

	.submenu li a:link,
	.submenu li a:visited {
	    text-decoration: none;
	    font-weight: bold;
	    color: #333;
	    padding: 0 5px 0 5px;
	}

	.selectform {
	    border: 1px solid #333;
	}

	.chauffeur {
	    background-color: #ddd;
	    border: 1px solid #F00;
	}

	#facturering {
	    border: #e5e5e5 solid 1px;
	    width: 90%;
	    margin: -1px auto 10px;
	    padding: 10px;
	    text-align: center;
	    font-size: 12px;
	    -webkit-border-top-left-radius: 5px;
	    -webkit-border-top-right-radius: 5px;
	    -webkit-border-bottom-left-radius: 5px;
	    -webkit-border-bottom-right-radius: 5px;
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	    -moz-border-radius-bottomleft: 5px;
	    -moz-border-radius-bottomright: 5px;
	    min-height: 300px;
	}

	#facturering table {
	    border: 0;
	    font-size: 10px;
	}

	#facturering table th {
	    border: 0;
	    border: 1px solid #ddd;
	}

	#facturering table td {
	    border: 1px solid #ddd;
	}

	#facturering table tr {
	    border: 0;
	}
	/*
 * YOOsearch
 */

	div#yoo-search {
	    width: 120px;
	    height: 19px;
	    padding: 0px;
	    margin: 0 0 10px 0;
	    background: url(/img/search_bg.png) 0 0 no-repeat;
	}

	div#yoo-search input {
	    width: 93px;
	    height: 14px;
	    padding: 3px 2px 2px 7px;
	    border: none;
	    outline: none;
	    background: none;
	    float: right;
	    color: #646464;
	    font-size: 11px;
	}

	div#yoo-search button {
	    width: 18px;
	    height: 19px;
	    padding: 0px;
	    border: none;
	    background: url(/img/search_button2.png) 0 0 no-repeat;
	    cursor: pointer;
	    text-indent: 100px;
	    line-height: 0px;
	}

	div#yoo-search:hover input,
	div#yoo-search input.sfhover {
	    color: #000000;
	}

	div#yoo-search input:focus,
	div#yoo-search input.sffocus {
	    color: #000000;
	}
	/*	Planner | Invoerscherm > invoertabel
	- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/

	#main {
	    clear: both;
	    background: #fff;
	    padding: 20px;
	    width: 1150px;
	    margin: 20px auto;
	    color: #fff;
	}

	#infobox {
	    padding: 5px 0 0 10px;
	    text-align: left;
	    font-size: 10px;
	    background-color: #ddd;
	    width: 350px;
	    min-height: 350px;
	    color: #333;
	    margin: 0 20px 0 0;
	    -moz-border-radius: 5px;
	}

	#main h3 {
	    color: #333;
	}

	#infobox ul {
	    padding: 0 10px 5px 30px;
	    list-style-image: url(/img/bullet.gif);
	}

	#infobox li {
	    font-weight: normal;
	    padding: 2px;
	}

	#infobox input,
	#infobox textarea {
	    display: block;
	    margin-left: 100px;
	    margin-top: -20px;
	}

	.forminput input {
	    width: 200px;
	    font-weight: bold;
	    color: #333;
	}

	#tip {
	    vertical-align: middle;
	}

	#invoertabel {
	    width: 90%;
	    color: #fff;
	    font-weight: bold;
	}

	#invoertabel input {
	    border: 2px solid #999;
	    padding: 1px;
	}

	#invoertabel h5 {
	    color: #333;
	    font-size: 13px;
	    height: 20px;
	    text-align: center;
	}

	.invoertabel_head {
	    color: #fff;
	    text-align: center;
	    background: #666;
	}

	.invoertabel_head td {
	    padding: 5px;
	}

	.invoertabel_tabel1 {
	    font-weight: bold;
	    padding: 10px;
	    border-right: 1px solid #ddd;
	}

	.invoertabel_tabel1 label {
	    text-align: right;
	}

	.invoertabel_tabel2 {
	    padding: 10px;
	    border-right: 2px solid #333;
	}

	.invoertabel_tabel3 {
	    padding: 10px;
	    border-right: 2px solid #ddd;
	}

	#invoertabel select {
	    display: block;
	    margin-left: 100px;
	    margin-top: -20px;
	}

	.invoertabel_tabel1 input,
	.invoertabel_tabel2 input,
	.invoertabel_tabel3 input {
	    display: block;
	    margin-left: 100px;
	    margin-top: -20px;
	}

	.invoertabel_bottom {
	    background-color: #333;
	}

	#invoertabel_verzenden {
	    margin-left: 176px;
	    font-size: 12px;
	    font-weight: bold;
	    background-color: #ffffff;
	}

	#bezet {
	    font-weight: normal;
	    margin: 10px 10px 10px 0;
	    padding: 5px;
	    background-color: #fdfdfd;
	    border: 1px dotted #ccc;
	    -moz-border-radius: 5px;
	}

	.adres {
	    background-color: #ccc;
	    padding: 5px;
	    margin: 5px 0 0 0;
	}

	.context {
	    cursor: pointer;
	}

	.contextmenu1 {
	    display: none;
	    background-color: #f6f6f6;
	    border: 1px solid #999;
	    border-right: 2px solid #999;
	    border-bottom: 2px solid #999;
	    width: 150px;
	    font-size: 12px;
	    position: absolute;
	    z-index: 1;
	}

	.contextmenu1 .icon {
	    background-color: #ddd;
	    width: 20px;
	    float: left;
	    padding: 5px 2px 0 2px;
	}

	.contextmenu1 ul {
	    list-style-type: none;
	}

	.contextmenu1 ul li {
	    margin: 5px 0 5px 0px;
	}

	.contextmenu1 ul li a:link,
	.contextmenu1 ul li a:visited {
	    color: #333;
	    margin: 0 0 0 10px;
	}

	.contextmenu1 ul li a:hover {
	    background-color: #ddd;
	    color: #fff;
	    border: 0;
	    text-decoration: none;
	}

	.contextmenu1 hr {
	    color: #ddd;
	}
	/*###########################################################
	# Afspraak wijzigen, afspraak kopieren, afspraak verplaatsen#
	###########################################################*/

	#ajaxIndex_form form {
	    font-size: 11px;
	    font-weight: bold;
	    color: #666;
	    text-align: left;
	}

	#ajaxIndex_form .lijn {
	    border-top: 1px solid #C0C0C0;
	    width: 100%;
	    margin: 0 0 30px 0;
	}

	#ajaxIndex_form input {
	    display: block;
	    margin-left: 180px;
	    margin-top: -20px;
	}

	#ajaxIndex_form label {
	    padding-left: 60px;
	}

	#ajaxIndex_form select {
	    display: block;
	    margin-left: 170px;
	    margin-top: -20px;
	}

	#ajaxIndex_form h3 {
	    padding-left: 20px;
	    font-size: 19px;
	    color: #333;
	    font-weight: normal;
	}

	#ajaxIndex_form .huisnr {
	    width: 20px;
	}

	#ajaxIndex_form .toevoeging {
	    width: 20px;
	    margin-left: 230px;
	}

	#ajaxIndex_form_wrap {}

	#ajaxIndex_form_wrap_left,
	#ajaxIndex_form_wrap_middle,
	#ajaxIndex_form_wrap_right {
	    float: left;
	    margin-right: 20px;
	    padding: 1px 0 0 0;
	    width: 400px;
	    height: 370px;
	}

	#ajaxIndex_form_wrap_left,
	#ajaxIndex_form_wrap_middle {
	    border-right: 1px dotted #ddd;
	}
	/*# KLANT WIJZIGEN EN AANMAKEN #

	*/

	#klant_form h3 {
	    margin-bottom: 20px;
	}

	#klant_form form {
	    font-size: 11px;
	    font-weight: bold;
	    color: #666;
	    text-align: left;
	}

	#klant_form .lijn {
	    border-top: 1px solid #C0C0C0;
	    width: 100%;
	    margin: 100px 0 30px 0;
	}

	#klant_form input {
	    display: block;
	    margin-left: 130px;
	    margin-top: -20px;
	}

	#klant_form label {
	    padding-left: 30px;
	}

	#klant_form select {
	    display: block;
	    margin-left: 130px;
	    margin-top: -20px;
	}

	#klant_form h3 {
	    padding-left: 20px;
	    font-size: 19px;
	    color: #333;
	    font-weight: normal;
	}

	#thickbox_klant_links {
	    float: left;
	}

	#thickbox_klant_rechts {
	    float: right;
	    margin: -300px 60px 0 0;
	}
	/*#########################################
	# Het busoptie venster aan de linker kant #
	#########################################*/

	.info_box {
	    overflow: auto;
	    position: relative;
	    width: 220px;
	    font-size: 9px;
	}

	.info_box ul {
	    list-style-type: none;
	    padding: 10px 0 50px 20px;
	}

	.info_box ul li {
	    float: left;
	    margin: 0 0 0 10px;
	}

	.info_box h4 {
	    padding: 5px;
	    color: #fff;
	    background-color: #666;
	    border-bottom: 1px solid #999;
	    -moz-border-radius-topright: 5px;
	    -moz-border-radius-topleft: 5px;
	}

	.busopties_links {
	    border: 1px dotted #ccc;
	    background-color: #f6f6f6;
	    overflow: auto;
	    -moz-border-radius: 5px;
	    background: #ddd;
	    position: relative;
	}

	.busopties_links ul {
	    list-style-type: none;
	    padding: 10px 0 50px 20px;
	}

	.busopties_links ul li {
	    float: left;
	    margin: 0 0 0 10px;
	}

	.busopties_links h4 {
	    padding: 5px;
	    color: #fff;
	    background-color: #666;
	    border-bottom: 1px solid #999;
	    -moz-border-radius-topright: 5px;
	    -moz-border-radius-topleft: 5px;
	}
	/*#################################
	# Bus overzicht op de hoofdpagina #
	#################################*/

	#kolom_1 {
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	    text-align: center;
	    padding: 10px 0 10px 0;
	}

	#kolom_1 select {
	    background-color: #f4f4f4;
	    border: 2px solid #f4f4f4;
	    color: #333;
	    font-weight: bold;
	    -moz-border-radius: 5px;
	}

	#kolom_1 option {
	    background-color: #f4f4f4;
	    color: #333;
	    border: 1px solid #ddd;
	}

	#kolom_2 {
	    -moz-border-radius-topleft: 5px;
	    -moz-border-radius-topright: 5px;
	    text-align: center;
	    padding: 10px 0 10px 0;
	}

	#kolom_2 select {
	    background-color: #f4f4f4;
	    border: 2px solid #f4f4f4;
	    color: #333;
	    font-weight: bold;
	    -moz-border-radius: 5px;
	}

	div#container {
	    margin: 40px auto;
	    width: 900px;
	}

	#afspraakinvoeren {
	    border: 1px solid #fff;
	}

	table#afspraakinvoeren th {
	    background-color: #fff;
	}

	table#afspraakinvoeren tr {
	    background-color: #eee;
	}

	table#afspraakinvoeren td {
	    border-right: 1px dashed #c9c9c9;
	}

	table#afspraakinvoeren td:hover {
	    background-color: #ddd;
	}

	a:active {
	    outline: none;
	}

	a:focus {
	    -moz-outline-style: none;
	}

	.infobox {
	    font-size: 10px;
	    padding: 7px;
	    border: 1px solid #d2cb0a;
	    background-color: #ffe9c3;
	    -moz-border-radius: 5px;
	    text-align: center;
	    width: 240px;
	    z-index: 99999;
	    margin: 5px auto;
	}

	div.cmxform fieldset {
	    margin: 10px 0 10px 5px;
	    border: 0px solid #ddd;
	    -webkit-border-radius: 5px;
	}

	div.cmxform legend {
	    padding: 0 2px;
	    font-weight: bold;
	    color: #333;
	}

	div.cmxform label {
	    display: inline-block;
	    line-height: 2;
	    text-align: right;
	    vertical-align: top;
	    color: #333;
	    font-size: 11px;
	    font-weight: bold;
	    text-transform: capitalize;
	}

	div.cmxform fieldset ol {
	    margin: 0;
	    padding: 0;
	}

	div.cmxform fieldset li {
	    list-style: none;
	    padding: 2px;
	    margin: 0;
	}

	div.cmxform em {
	    font-weight: bold;
	    font-style: normal;
	    color: #f00;
	    font-size: 10px;
	}

	div.cmxform label,
	textarea {
	    width: 90px;
	    /* Width of labels */
	}

	.cmxform select {
	    /*		width: 100px;
*/
	}

	div.cmxform input {
	    border: 1px solid #999;
	    -moz-border-radius: 5px;
	    padding: 2px;
	    color: #333;
	}

	div.cmxform input:hover {
	    border: 1px solid #666;
	    color: #333;
	}

	#load {
	    position: absolute;
	    left: 40%;
	    top: 20%;
	    font-size: 10px;
	    border: 1px solid #ddd;
	    -moz-border-radius: 5px;
	    padding: 5px;
	    font-family: tahoma;
	    font-weight: bold;
	    background-color: #fff;
	}

	img {
	    border: 0;
	}

	.toggle {
	    background-color: #999;
	    padding: 10px;
	    -moz-border-radius: 5px;
	}

	.link-toggle {
	    color: #333;
	    text-decoration: none;
	    font-size: 10px;
	    font-weight: bold;
	    padding: 5px;
	    background-color: #999;
	    color: #333;
	    -moz-border-radius: 5px;
	}

	.toggle-price {
	    background-color: #999;
	    padding: 5px;
	    -moz-border-radius: 5px;
	}

	.link-toggle-price {
	    text-decoration: none;
	    font-size: 10px;
	    font-weight: bold;
	    padding: 5px;
	    margin: 0 0 10px 95px;
	    background-color: #999;
	    color: #333;
	    -moz-border-radius: 5px;
	    /*
		-moz-border-radius-topleft:5px;
		-moz-border-radius-topright:5px;
		*/
	}

	.expander img {
	    margin: 10px 0 0 0;
	}

	#button {
	    padding: 5px;
	    font-size: 10px;
	    font-weight: bold;
	}

	#afspraak_verwerken {
	    position: absolute;
	    border-top: 10px solid #333;
	    border-bottom: 10px solid #333;
	    -moz-border-radius-topright: 5px;
	    -moz-border-radius-topleft: 5px;
	    height: 100px;
	}

	.thickbox_iframe {
	    font-family: calibri;
	    font-size: 12px;
	}

	#laatste_actie {
	    margin: -20px 0 0 0;
	    float: left;
	    border: 1px solid #333;
	    background-color: #333;
	    color: #fff;
	    padding: 2px;
	    font-size: 10px;
	    font-weight: bold;
	}

	#bus tr {
	    height: 20px;
	}

	.suggestBox_vertrek {
	    position: absolute;
	    margin: 10px 0 0 80px;
	    width: 200px;
	    background-color: #212427;
	    -moz-border-radius: 7px;
	    -webkit-border-radius: 7px;
	    border: 2px solid #000;
	    color: #fff;
	}

	.suggestList_vertrek {
	    margin: 0px;
	    padding: 0px;
	    font-weight: bold;
	}

	.suggestList_vertrek li {
	    margin: 0px 0px 3px 0px;
	    padding: 3px;
	    cursor: pointer;
	}

	.suggestList_vertrek li:hover {
	    background-color: #659CD8;
	}

	.suggestList_vertrek span {
	    font-size: 10px;
	    position: relative;
	    margin-top: 0px;
	    font-weight: normal;
	}

	.suggestBox_bestemming {
	    position: absolute;
	    margin: 10px 0 0 80px;
	    width: 200px;
	    background-color: #212427;
	    -moz-border-radius: 7px;
	    -webkit-border-radius: 7px;
	    border: 2px solid #000;
	    color: #fff;
	}

	.suggestList_bestemming {
	    margin: 0px;
	    padding: 0px;
	    font-weight: bold;
	}

	.suggestList_bestemming li {
	    margin: 0px 0px 3px 0px;
	    padding: 3px;
	    cursor: pointer;
	}

	.suggestList_bestemming li:hover {
	    background-color: #659CD8;
	}

	.suggestList_bestemming span {
	    font-size: 10px;
	    position: relative;
	    margin-top: 0px;
	    font-weight: normal;
	}
	/* ######## Rittenregistratie ######## */

	#info {
	    background-color: #FBFFC3;
	    width: 400px;
	    height: 40px;
	    margin: 0px auto;
	    padding: 10px;
	    vertical-align: middle;
	    text-align: inherit;
	    border: 1px solid #ddd;
	    font-size: 11px;
	}

	#factureringtabel {
	    text-align: center;
	    margin: 0 auto;
	}

	#factuur_zeevang_menu ul {
	    margin: 10px 0 -2px 0;
	}

	#factuur_zeevang_menu li {
	    display: inline;
	    list-style-type: none;
	}

	#factuur_zeevang_menu li a:link,
	#factuur_zeevang_menu li a:visited {
	    text-decoration: none;
	    display: inline;
	    padding: 4px 12px 3px 12px;
	    margin: 0 5px 0 5px;
	}

	#factuur_zeevang_menu li a:hover {
	    text-decoration: underline;
	    display: inline;
	    padding: 4px 12px 3px 12px;
	    margin: 0 5px 0 5px;
	}

	.editable_textarea {
	    border: 1px solid #333;
	    margin: 0 0 0 400px;
	}
	/* ERROR BOX */

	#error {
	    padding: 5px 7px 7px 30px;
	    background: #ffffcc url(/img/cross_circle.png) no-repeat 5px;
	    font-size: 12px;
	    border: 1px solid #d2cb0a;
	    background-color: #ffe9c3;
	    -moz-border-radius: 5px;
	    text-align: center;
	    width: 440px;
	    z-index: 99999;
	    margin: 20px auto;
	}
	/* BEHEER */

	#beheer_table {
	    margin: 10px auto;
	}

	#beheer_table th {
	    background-color: #333;
	    color: #fff;
	    padding: 5px;
	    border-bottom: 2px solid #333;
	}

	.bedrag-wrapper {
		position: relative;
	}

	.formattedVal {
		position: absolute;
    left: 94%;
    top: -5px;
	}

